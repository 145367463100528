import store from "../store/store";
import { actions } from "../store/ducks/auth.duck";
var axios = require("axios");
const baseURL = `${process.env.REACT_APP_BASE_URL}/api`;

export function CallApi(method, url, params, data) {
  return axios({
    method: method,
    baseURL: baseURL,
    url: url,
    params: params,
    data: data
  })
    .then(response => {
      return response;
    })
    .catch(function(error) {
      if (!error) {
        throw error;
      } else if (error.response && error.response.status == 401 && url != "/student/auth/login") {
        // this.props.actions.logout();
        store.dispatch(actions.logout());
      } else return error.response;
    });
}
