import React from "react";
import { CallApi } from "../../api";
import { actions } from "../../store/ducks/exam.duck";
import Swal from 'sweetalert2';
import {connect} from 'react-redux';

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chosen: props.question.question_choice_id,
      question: props.question
    };
    if(props.question.question_choice_id != null) props.addAnswer(props.count)
  }
  
  _chosen = e => {
    this.setState({
      chosen: e.target.value
    },()=>{
      this.save();
    });
  };

  save = () => {
    if(this.props.status == 'In Progress'){
      CallApi(
        "POST",
        "/exam/save-student-course-question-answer",
        {},
        {
          examId: this.props.examId,
          questionId: this.props.question.id,
          choiceId: this.state.chosen
        }
      ).then(response => {
        switch (response.status) {
          case 200:
            {
              console.log(response, "200");
              this.props.onAnswer(this.props.count)
            }
            break;
          case 202:
            {

              Swal.fire({
                position: "top-end",
                icon: "error",
                title: `exam time is out`,
                showConfirmButton: false,
                timer: 3000,
                toast: true,
              });
              this.props.timeOut()
            }
            break;
          case 500:
            {
              Swal.fire({
                toast: true,
                position: "top-end",
                icon: "warning",
                title: `question ${this.props.count} failed to save please choose again`,
                showConfirmButton: false,
                timer: 3000
              });
            }
            break;
          default:
            {
              Swal.fire({
                toast: true,
                position: "top-end",
                icon: "warning",
                title: `question ${this.props.count} failed to save please choose again`,
                showConfirmButton: false,
                timer: 3000
              });
            }
        }
      });
    }
  };
  render() {
    const { chosen, question } = this.state;
    const { active, count, status } = this.props;
    return (
      <div style={active == count ? {} : { display: "none" }}>
        <div className="question p-4 text-center border rounded bg-white mt-5 ">
          <h6 className="mt-n5 text-center text-white bg-warning p-3 rounded-pill mb-4">
            Question {count}
          </h6>
          <h5 className="question-content d-inline-block">
            {question.question}
          </h5>
        </div>
        <div className="form-group row mt-3 mr-4">
          {question.choices.map((choice, i) => {
            return (
              <div className="col-6 " key={choice.id}>
                <div className="form-check form-check-inline z-radio">
                  <input
                    className="form-check-input position-absolute"
                    type="radio"
                    id={choice.id}
                    value={choice.id}
                    checked={choice.id == chosen}
                    onChange={this._chosen}
                    disabled={status == "Completed"}
                  />
                  <label className="item text-center py-4 " htmlFor={choice.id}>
                    <h6 className="font-weight-bold">{choice.choice}</h6>
                  </label>
                  <br />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
const mapActionsToProps = {
  addAnswer: actions.addAnswer
}
export default connect(null, mapActionsToProps)(Question);