import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { CallApi } from "../../api";
import Moment from "moment";

export default class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentCourseId: props.id,
      attendances: []
    };
  }
  componentDidMount(){
    this.getAttendances();
  }
  getAttendances = () => {
    CallApi('GET', `/student/get-student-course-attendances/${this.props.id}`, {}, {})
    .then(response => {
      if(response.status == 200){
        this.setState({
          attendances: response.data.data
        })
      }
    })
  }
  render() {
    const { attendances } = this.state;
    return (
      <div className="Attendance mt-5">
        <div className="kt-portlet__head bg-light">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Attendance </h3>
          </div>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Level</th>
              <th>Date</th>
              <th>Attendance</th>
            </tr>
          </thead>
          <tbody>
            {attendances.map((attendance, i) => {
              return (
                <tr key={i}>
                  <td>{attendance.level}</td>
                  <td>{Moment(attendance.day).format("ll")}</td>
                  <td>{attendance.attendance}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}
