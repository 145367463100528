import { persistReducer } from "redux-persist";

export const actionTypes = {
  setDataTiekct : "setDataTiekct",

};

const initialAuthState = {
    tickts: [],
};


export const reducer = 
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.setDataTiekct: {
          console.log(action ,  'action action')
        const { tickts } = action.payload;
        return { tickts };
      }

      default:
        return state;
    }
  }

export const actions = {
  setTickets: tickts => ({ type: actionTypes.setDataTiekct, payload: { tickts } }),

};

