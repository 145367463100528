import React, { Component } from "react";
import { connect } from "react-redux";
import CourseSummary from "./CourseSummary";
import { CallApi } from "../../api";
class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: []
    }
  }

  async componentDidMount() {
    CallApi("GET", "/student/courses", {}, {}).then(response => {
      console.log(response.data, "data");
      if (response.status == 200) {
        this.setState({ coursesData: response.data });
      }
    });
  }

  render() {
    const { coursesData } = this.state;
    return (
      <div className="Courses-page">
        <div className="container">
          <div className="py-5">
            <div className="row">
              {coursesData ? (
                coursesData.map(course => {
                  return <CourseSummary key={course.student_course_id} data={course} />;
                })
              ) : (
                <div className="loader-parent">
                  <div className="loader mx-auto "></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(Courses);
