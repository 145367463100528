/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
const imgURL = process.env.REACT_APP_BASE_IMGURL;

class UserProfile extends React.Component {
  render() {
    const { user, showHi, showBadge } = this.props;

    return (
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--user"
        drop="down"
        alignRight
      >
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          {showHi && <span className="kt-header__topbar-welcome ">Hi,</span>}

          {showHi && (
            <span className="kt-header__topbar-username text-primary ">
              {user ? user.name : null}
            </span>
          )}
          {showBadge && user.profile_image && (
            <img
              alt="Pic"
              className="rounded-circal img-fluid"
              style={{
                maxWidth: "33%",
              }}
              src={user ? imgURL + user.profile_image : null}
            />
          )}
          {showBadge && !user.profile_image && (
            <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold kt-hidden-">
              {user ? user.name[0] : null}
            </span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          <div
            className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`,
            }}
          >
            <div className="kt-user-card__avatar">
              {user && user.profile_image && (
                <img
                  alt="Pic"
                  src={user ? imgURL + user.profile_image : null}
                />
              )}
              {user && !user.profile_image && (
                <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                  {user ? user.name[0] : null}
                </span>
              )}
            </div>
            <div className="kt-user-card__name">
              {user ? user.fullname : null}
            </div>
            <div className="kt-user-card__badge">
              <span className="btn btn-success btn-sm btn-bold btn-font-md">
                23 messages
              </span>
            </div>
          </div>
          <div className="kt-notification">
            <Link className="kt-notification__item" to="/My-Profile">
              <div className="kt-notification__item-icon">
                <i className="flaticon2-calendar-3 kt-font-success" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  My Profile
                </div>
                <div className="kt-notification__item-time">
                  Account settings and more
                </div>
              </div>
            </Link>
            <div className="kt-notification__custom">
              <Link
                to="/logout"
                className="btn btn-label-brand btn-sm btn-bold"
              >
                Sign Out
              </Link>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user: user,
});

export default connect(mapStateToProps)(UserProfile);
