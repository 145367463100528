import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";
import Courses from "../courses/Courses";
import DescCouses from "../courses/DescCouses";
import MyProfile from "../myProfile/MyProfile"
import Ticket from "../ticket/Ticket";
import TicketDetail from "../ticket/TicketDetail";
import ResetPassword from "../myProfile/ResetPassword";
import SetPassword from "../myProfile/SetPassword";
import Exams from "../exams/Exams";
import ExamDetails from "../exams/ExamDetails";

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/ResetPassword" component={ResetPassword} />
        <Route path="/Courses-Page" exact component={Courses} />
        <Route path="/Courses-Page/:DescCouses_id" component={DescCouses} />
        <Route path="/Ticket" component={Ticket} />
        <Route path="/Exams/:id" component={ExamDetails} />
        <Route path="/Exams" component={Exams} />
        <Route path="/set-password/:token" component={SetPassword} />
        <Route path="/TicketDetail/:ticket_id" component={TicketDetail} />
        <Route path="/My-Profile" component={MyProfile} />
        <Route path="/docs" component={DocsPage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
