/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Table } from 'react-bootstrap';
import { CallApi } from "../api";
import Moment from 'moment';

export default function CoursePayments({ id }) {
  const [receipts, setReceipts] = useState([]);
  const [payments, setPayments] = useState([]);
  useEffect(()=>{
    CallApi("GET", `/student/get-student-course-payments/${id}`, {}, {})
    .then(response => {
      if(response.status == 200){
        setReceipts(response.data.data)
      }
    })
  }, [])
  useEffect(()=>{
    let payment = [];
    receipts.map(receipt=>{
      receipt.payments.map(pay =>{
        payment.push({name: receipt.name, ...pay})
      })
    })
    setPayments(payment)
  },[receipts])
  return (
    <>
      <div className="kt-portlet__head bg-light">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title"> Payment </h3>
        </div>
      </div>
      <Table striped bordered hover>
          <thead>
            <tr>
              <th>Attachment</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
          {payments.map((payment, i) => {
              return (
                <tr key={i}>
                  <td>{payment.name}</td>
                  <td>{Moment(payment.date).format("ll")}</td>
                  <td> {payment.amount}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
    </>
  );
}
