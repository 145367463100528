import React, { Fragment } from "react";

const NextSessions_Summary = ({ data_next, apologize }) => {
  return (
    <div className="next-session kt-portlet p-4 ">
      { Object.keys(data_next).length ? (
        <>
          <h6> Next Session </h6>
          <h5> {data_next ? data_next.course_name : null} </h5>
          <p>
            {" "}
            <i className="fas fa-calendar-alt"></i>{" "}
            {data_next ? data_next.day : null}{" "}
          </p>
          <div className="row">
            <div className="col-6">
              <i className="fas fa-clock"></i> From
              {data_next ? data_next.from_time : null}
            </div>
            <div className="col-6">
              <i className="fas fa-clock"></i> To{" "}
              {data_next ? data_next.to_time : null}{" "}
            </div>
          </div>
          <button
            // disabled={data_next.status == "Apologized" ? "disabled" : null}
            id={data_next.id}
            onClick={e => apologize(e.target.id)}
            id={data_next.id}
            className="btn btn-danger btn- mt-3 btn-block"
          >
            {data_next ? data_next.status : null}
          </button>
        </>
      ):
      <h5 className="text-info mx-auto">No Scheduled Sessions</h5>
      }
    </div>
  );
};

export default NextSessions_Summary;
