import React from "react";
import "./timer.scss";
const Timer = ({ hours, minutes, seconds, completed }) => {
  return (
    <div className="timer">
      <span>
        {hours}:{minutes}:{seconds}
      </span>
    </div>
  );
};
export default Timer;
