import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  SetTime: "[Set Time] Action",
  SetExam: "[Set Exam] Action",
  addAnswer: "[add answer] Action",
  defaultAnswers: "[default answers] Action"
};

const initialExamState = {
  timer: undefined,
  exam: undefined,
  answers: []
};

export const reducer = persistReducer(
  { storage, key: "exam", whitelist: ["exam", "timer"] },
  (state = initialExamState, action) => {
    switch (action.type) {
      case actionTypes.SetTime: {
        const { timer } = action.payload;
        return { ...state, timer };
      }
      case actionTypes.addAnswer: {
        const { answer } = action.payload;
        return { ...state, answers: [...state.answers, answer] };
      }
      case actionTypes.defaultAnswers: {
        return { ...state, answers: [] };
      }
      case actionTypes.SetExam: {
        const { exam } = action.payload;
        return { ...state, exam };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  setTimer: timer => ({ type: actionTypes.SetTime, payload: { timer } }),
  setExam: exam => ({ type: actionTypes.SetExam, payload: { exam } }),
  addAnswer: answer => ({ type: actionTypes.addAnswer, payload: { answer } }),
  defaultAnswers: () => ({ type: actionTypes.defaultAnswers })
};
