import React, { Component } from "react";
import TicketSummary from "./TicketSummary";
import "./Ticket.scss";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import * as tickets from "../../store/ducks/ticktes.ducks";
import { CallApi } from "../../api";
import { useFormik } from "formik";

class Ticket extends Component {
  state = {
    tickets: [],
    categories: [],
    new_ticket: false,
    subject: null,
    category: null,
    desc: null,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmite = (e) => {
    e.preventDefault();
    const { subject, desc, category } = this.state;

    if (!subject && !desc && !category) {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        title: "Please enter the missing data",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      let bodyFormData = new FormData();
      bodyFormData.append("description", desc);
      bodyFormData.append("subject", subject);
      bodyFormData.append("ticket_category_id", category);
      this.setState({
        desc: null,
        category: null,
        subject: null,
      });
      CallApi("POST", "/student-services/ticket", {}, bodyFormData).then(
        (response) => {
          if (response.status == 200) {
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Ticket created Successfully",
              showConfirmButton: false,
              timer: 3000,
            });
            this.setState({ new_ticket: !this.state.new_ticket });
            // get tickets
            this.getTicketsData();
          } else {
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "error",
              title: "Failed to create new ticket",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      );
    }
  };

  componentDidMount() {
    this.getTicketsData();
    this.getTicketsCategoriesData();
  }
  getTicketsData = () => {
    CallApi("GET", "/student-services/tickets", {}, {}).then((response) => {
      if (response.status === 200) {
        this.props.setTickets(response.data);
      }
    });
  };
  getTicketsCategoriesData = () => {
    CallApi("GET", "/student-services/ticket-categories", {}, {}).then(
      (response) => {
        if (response.status === 200) {
          this.setState({ categories: response.data.data });
        }
      }
    );
  };
  componentWillReceiveProps(next) {
    console.log(next, "next");
  }

  handel_new_ticket = () => {
    this.setState({ new_ticket: !this.state.new_ticket });
  };

  render() {
    const { categories } = this.state;
    const { tickts } = this.props;

    console.log(tickts, "tickts tickts");
    console.log(this.props, "props props");

    return (
      <div className="Ticket py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="header-ticket bg-white p-3 mb-4">
                <div className="row">
                  <div className="col-6">
                    <h1 className="h4 d-inlinr-block"> Ticket </h1>
                  </div>
                  <div className="col-3 offset-md-3 mr-auto">
                    <button
                      type="button"
                      className={`btn  btn-block  ${
                        this.state.new_ticket ? "btn-defuilt" : "btn-info"
                      } `}
                      onClick={this.handel_new_ticket}
                    >
                      {this.state.new_ticket ? "Close" : "Add New"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.new_ticket ? (
            <div className="new-replay p-4 mb-4 bg-white">
              <form onSubmit={this.handleSubmite}>
                <div className="row">
                  <div className="col-md-6">
                    <select
                      onChange={this.handleChange}
                      id="category"
                      className="form-control"
                      required
                    >
                      <option value=""> select category </option>
                      {categories
                        ? categories.map((catg) => {
                            return (
                              <option key={catg.id} value={catg.id}>
                                {" "}
                                {catg.name}{" "}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={this.handleChange}
                        className="form-control"
                        id="subject"
                        required
                        minLength="2"
                        placeholder="subject"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <textarea
                      onChange={this.handleChange}
                      id="desc"
                      cols="30"
                      rows="5"
                      placeholder="dscription"
                      className="form-control"
                      minLength="10"
                      required
                    ></textarea>
                  </div>
                  <div className="col-md-3">
                    <button className="btn btn-info btn-block mt-3">
                      {" "}
                      Send{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : null}

          <div className="row">
            {console.log(tickts)}
            {tickts ? (
              tickts.map((tick) => {
                console.log(tick.category);
                return <TicketSummary key={tick.id} data={tick} />;
              })
            ) : (
              <div className="loader-parent">
                <div className="loader mx-auto "></div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user }, tickets: { tickts } }) => ({
  user,
  tickts,
});

const mapdispatchToProps = {
  setTickets: tickets.actions.setTickets,
};

export default connect(mapStateToProps, mapdispatchToProps)(Ticket);
