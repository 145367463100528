import React, { Component } from "react";
import "./exmas.scss";
import ExamSummary from "./ExamSummary";
import { CallApi } from "../../api";
import { actions } from "../../store/ducks/exam.duck";
import { connect } from 'react-redux';

class Exams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exams: []
    };
  }
  componentDidMount() {
    this.getExamsData();
    this.props.defaultAnswers();
  }
  getExamsData = () => {
    CallApi("GET", "/exam/get-student-exams", {}, {}).then(response => {
      if (response.status == 200) {
        this.setState({
          exams: response.data.data
        });
      }
    });
  };
  render() {
    const { exams } = this.state;
    return (
      <div className="Exams py-5">
        <div className="container">
          <div className="row">
            {exams.map((exam, i) => {
              return <ExamSummary key={i} exam={exam} />;
            })}
          </div>
        </div>
      </div>
    );
  }
}
const mapActionsToProps ={
  defaultAnswers: actions.defaultAnswers
}
export default connect(null, mapActionsToProps)(Exams)