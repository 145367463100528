import React from "react";
import { Link } from "react-router-dom";
import imageExam from "../../../_metronic/_assets/media/img/online-courses.jpg";
import Moment from "moment";
import { useDispatch } from "react-redux";
import { actions } from "../../store/ducks/exam.duck";

const ExamSummary = ({ exam }) => {
  const dispatch = useDispatch();
  const _setExam = () => {
    dispatch(actions.setExam(exam));
  };
  return (
    <div className="col-md-6">
      <Link to={`/Exams/${exam.id}`} onClick={_setExam}>
        <div className="item-exam bg-white rounded p-4 my-3 ">
          <div className="row">
            <div className="col-md-5">
              <img src={imageExam} className="img-fluid rounded" alt="" />
            </div>
            <div className="col-md-7">
              <h4 className="text-dark"> {exam.category.name} </h4>
              <span className="d-block text-muted">
                {" "}
                <i className="fas fa-calendar-alt"></i>{" "}
                {Moment(exam.date).format("LL")}{" "}
              </span>
              {exam.status == "In Progress" && (
                <span className="d-block text-muted">
                  {" "}
                  <i className="fas fa-clock"></i> {Moment(exam.date).format("LT")}{" "}
                </span>
              )}
              {exam.status == "Completed" && (
                <span className="d-block text-muted">
                  {" "}
                  <i className="fas fa-clock"></i>
                  {Moment(exam.date).format("LT")}{" "}
                  {Moment(exam.end_at).format("LT")}
                </span>
              )}
              <div className="mt-2 ">
                <span className="text-muted mt-3">{exam.course_name}</span>
                <small className="bg-info p-2 float-right text-white status-exams  rounded">
                  {exam.status}
                </small>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ExamSummary;
