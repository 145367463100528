import React, { Component } from "react";
import "./Ticket.scss";
import TicketSummary from "./TicketSummary";
import { connect } from "react-redux";
import Reply from "./Reply";
import Swal from "sweetalert2";
import { CallApi } from "../../api";

class TicketDetail extends Component {
  state = {
    newReply: false,
    replies: [],
    ticket_Reply: [],
    message: "",
  };

  componentDidMount() {
    this.getTicketRepliesData();
  }

  getTicketRepliesData = () => {
    const id = this.props.match.params.ticket_id;
    CallApi("GET", `/student-services/ticket-replies/${id}`, {}, {}).then(
      (response) => {
        if (response.status === 200) {
          this.setState({ replies: response.data });
        }
      }
    );
  };
  addNewReply = () => {
    this.setState({
      newReply: !this.state.newReply,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const id = this.props.match.params.ticket_id;
    const { message, replies } = this.state;
    if (!message) {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        title: "Please enter your replay",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      let bodyFormData = new FormData();
      bodyFormData.append("ticket_id", replies.id);
      bodyFormData.append("ticket_status_id", replies.ticket_status_id);
      bodyFormData.append("message", message);

      CallApi("POST", "/student-services/ticket-reply", {}, bodyFormData).then(
        (response) => {
          if (response.status == 200) {
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Replay sent Successfully",
              showConfirmButton: false,
              timer: 3000,
            });
            this.setState({ newReply: !this.state.newReply });
            this.getTicketRepliesData();
          } else {
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "error",
              title: "Failed to send your replay",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      );
    }
  };

  render() {
    const { replies } = this.state;
    const { user } = this.props;

    return (
      <div className="TicketDetail py-5 ">
        <div className="container">
          <div className="row">
            <TicketSummary data={this.state.replies} />
          </div>

          {/* add replay */}
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn btn-dark btn-block"
                onClick={this.addNewReply}
              >
                {this.state.newReply == false ? " Add Reply " : " Close Reply "}
              </button>
            </div>
          </div>

          {/* form */}
          {this.state.newReply ? (
            <div className="row ">
              <div className="col-md-12">
                <form
                  className="bg-white p-4 mt-4 border "
                  onSubmit={this.handleSubmit}
                >
                  <h5> Add Reply </h5>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="message"
                      onChange={this.handleChange}
                      id="message"
                      rows="5"
                      required
                      minLength="10"
                    ></textarea>
                  </div>
                  <div className="row">
                    <div className="col-md-3 mr-auto">
                      <button className="btn btn-info btn-block"> Send </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : null}

          <div className="row mt-4">
            {replies.replies ? (
              replies.replies.map((reply_data) => {
                return (
                  <Reply
                    key={reply_data.id}
                    data={reply_data}
                    userData={user}
                  />
                );
              })
            ) : (
              <div className="loader-parent">
                <div className="loader mx-auto "></div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user }, tickets: { tickts } }) => ({
  user,
  tickts,
});

export default connect(mapStateToProps)(TicketDetail);
