import React from "react";
import { Link } from "react-router-dom";
import "./Ticket.scss";

const TicketSummary = ({ data }) => {
  if (data.status === undefined) {
    console.log("UN");
  } else {
    console.log(data.status.name);
  }
  return (
    <div className="col-xl-12">
      <Link to={"/TicketDetail/" + data.id}>
        <div className="card mb-3 TicketSummary ">
          <div className="card-body">
            <h5 className="card-title text-dark">
              {data ? data.subject : null}{" "}
            </h5>
            <div className="row col-xl-12 ticket-info-wrapper">
              <div className="col-sm text-muted">
                <i className="fas fa-calendar-alt"></i> Last Updated :
                {data ? data.updated_at : null}
              </div>
              <div className="col-sm text-muted">
                <i className="fas fa-calendar-alt"></i> Created At :
                {data ? data.created_at : null}
              </div>
              <div className="col-sm text-muted">
                <span className="text-info px-2 py-1 rounded-pill ">
                  <span style={{ color: "red" }}>
                    {" "}
                    Priority : {data ? data.piriority : null}
                  </span>
                </span>
              </div>

              <div className="col-sm text-muted">
                <span className="text-info px-2 py-1 rounded-pill">
                  <span style={{ color: "green" }}>
                    Status : {data && data.status ? data.status.name : null}
                  </span>
                </span>
              </div>
              <div className="col-lg-5 text-muted">
                <span className="text-info px-2 py-1 rounded-pill ">
                  <span style={{ color: "black" }}>
                    Category :{" "}
                    {data && data.category ? data.category.name : null}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default TicketSummary;
