import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { requestPassword } from "../../crud/auth.crud";
import Swal from "sweetalert2";
import { CallApi } from "../../api";

class ForgotPasswordReg extends Component {
  state = { isRequested: false };

  render() {
    const { intl } = this.props;
    const { isRequested } = this.state;

    // if (isRequested) {
    //   return <Redirect to="/auth" />;
    // }

    return (
      <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3>
                <FormattedMessage id="AUTH.RESET.TITLE" />
              </h3>
            </div>

            <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              validate={values => {
                const errors = {};

                if (!values.password) {
                  errors.password = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                }else if(/(?=(.*[0-9]))((?=.*[A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]))^.{8,}$/.test(values.password)){
                    errors.password = "Password should have 1 lowercase letter, 1 uppercase letter, 1 number and be at least 8 characters"
                }
                if (!values.confirmPassword) {
                  errors.confirmPassword = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                }
                if (values.password != values.confirmPassword) {
                  errors.confirmPassword = "Confirm password did not match"
                }
                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                CallApi(
                  "POST",
                  `/student/auth/set-student-password/${this.props.match.params.token}`,
                  {},
                  {
                    password: values.password,
                    password_confirmation: values.confirmPassword
                  }
                ).then(response => {
                  if (response.status == 200) {
                    console.log(response.data);
                    this.props.login(response.data.data.access_token);
                    this.props.fulfillUser(response.data.data.user);
                  }
                  if (response.status == 422) {
                    setStatus(
                      intl.formatMessage(
                        { id: "AUTH.VALIDATION.FORGET.PASSWARD" },
                        { message: response.data.errors.password }
                      )
                    );
                  }
                  if (response.status == 400) {
                    Swal.fire({
                      toast: true,
                      position: "top-end",
                      icon: response.data.type,
                      text: response.data.message,
                      timer: 3000
                    });
                    setSubmitting(false);
                  }
                  console.log(response);
                  setSubmitting(false);
                });
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                <form onSubmit={handleSubmit} className="kt-form">
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}

                  <div className="form-group">
                    <TextField
                      type="password"
                      label="Password"
                      margin="normal"
                      fullWidth={true}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      helperText={touched.password && errors.password}
                      error={Boolean(touched.password && errors.password)}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      type="password"
                      label="Confirm password"
                      margin="normal"
                      fullWidth={true}
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirmPassword}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                    />
                  </div>

                  <div className="kt-login__actions">
                    <Link
                      to="/auth/forgot-password"
                      className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                    >
                      Back
                    </Link>

                    <button
                      type="submit"
                      className="btn btn-primary btn-elevate kt-login__btn-primary"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(ForgotPasswordReg));
