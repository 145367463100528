import React, { Component } from "react";
import authentic from "../../../_metronic/_assets/media/img/authentic.png";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { CallApi } from "../../api";

export default class SetPassword extends Component {
  state = {
    old_password: "",
    password: "",
    password_confirmation: ""
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  componentDidMount() {}

  handleSubmite = e => {
    e.preventDefault();
    const { password, password_confirmation } = this.state;
    console.log(password, password_confirmation);

    if (password != password_confirmation) {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        title: "the new password is not matching",
        showConfirmButton: false,
        timer: 3000
      });
    } else {
      let bodyFormData = new FormData();
      bodyFormData.append("password", password);
      bodyFormData.append("password_confirmation", password_confirmation);
      CallApi("POST", "/student/set-password", {}, bodyFormData).then(
        response => {
          console.log(response.data, "res_Status");
          if (response.status == 200) {
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "the new password is saved",
              showConfirmButton: false,
              timer: 3000
            });
          } else {
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "error",
              title: "sorry the old password is not correct",
              showConfirmButton: false,
              timer: 3000
            });
          }
        }
      );
    }
  };

  render() {
    const token = this.props.match.params.token;
    console.log(token.slice(6), "token slice");
    return (
      <div className="ResetPassword py-5">
        <div className="container">
          <div className="content kt-portlet">
            <div className="col-md-10 mx-auto py-5">
              <div className="col-md-4 mx-auto"></div>
              <form className="" onSubmit={this.handleSubmite}>
                <div className="row">
                  <div className="col-md-6">
                    <h1 className="h3 mb-4"> Set Password </h1>
                    <div className="form-group">
                      <label htmlFor="password">New Password</label>
                      <input
                        type="password"
                        className="form-control"
                        onChange={this.handleChange}
                        id="password"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password_confirmation">
                        Repeat New Password
                      </label>
                      <input
                        onChange={this.handleChange}
                        type="password"
                        className="form-control"
                        id="password_confirmation"
                        required
                      />
                    </div>
                    <button className="btn btn-info btn-block"> Save </button>
                  </div>
                  <div className="col-md-4 offset-md-2">
                    <img
                      src={authentic}
                      className="img-fluid d-block mt-5 mb-4 mx-auto"
                      alt=""
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
