import React, { Component } from "react";
import Timer from "./timer";
import Countdown from "react-countdown";
import { actions } from "../../store/ducks/exam.duck";
import Question from "./Question";
import Swal from "sweetalert2";
export default class CountdownExam extends Component {
  constructor(props) {
    super(props);
    console.log(props);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }
  render() {
    return (
      <div className="submit-exam">
        <Countdown
          onComplete={() => {
            this.props.setTimer(false);
          }}
          date={
            Date.now() +
            (this.props.exam.category.duration_in_minutes * 60 +
              this.props.exam.remainingSeconds) *
              1000
          }
          renderer={Timer}
        />
        <br />
      </div>
    );
  }
}
