import React, { Component } from "react";
import CourseExams from "../../widgets/CourseExams";
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import { connect } from "react-redux";
import placeholder from "../../../_metronic/_assets/media/img/placeholder.png";
import "./courses.scss";
import Attendance from "./Attendance";
import DownloadFiles from "../../widgets/DownloadFiles";
import CoursePayments from "../../widgets/CoursePayments";
import { ConfirmationModal } from "../../common/confirmation";
import { CallApi } from "../../api";
import Moment from "moment";
import Swal from "sweetalert2";
const imgURL = process.env.REACT_APP_BASE_IMGURL;

class DescCouses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseData: null,
      reportData: [],
      details: null,
      nextSession: null,
      previousSession: null,
      remaining: 0
    };
  }

  componentDidMount() {
    const id = this.props.match.params.DescCouses_id;
    // get course detail
    CallApi("GET", `/student/get-student-course-details/${id}`, {}, {}).then(
      response => {
        if (response.status == 200) {
          console.log(response, "data");
          this.setState({
            courseData: response.data.data.course,
            details: response.data.data
          });
        }
      }
    );
    // get next session data
    CallApi(
      "GET",
      `/student/get-student-course-next-session/${id}`,
      {},
      {}
    ).then(response => {
      if (response.status == 200) {
        this.setState({ nextSession: response.data.data });
      }
    });
    CallApi(
      "GET",
      `/student/get-student-course-previous-session/${id}`,
      {},
      {}
    ).then(response => {
      if (response.status == 200) {
        this.setState({ previousSession: response.data.data });
      }
    });
    // get remaining
    CallApi("GET", `/student/get-student-course-remaining/${id}`, {}, {}).then(
      response => {
        if (response.status == 200) {
          this.setState({ remaining: response.data.data });
        }
      }
    );
  }

  _apologize = id => {
    CallApi("PUT", `/student/apologize/${id}`, {}, {}).then(response => {
      if (response.status == 200) {
        console.log(response.data, "apologizeRes");
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Apologize The Next Session",
          showConfirmButton: false,
          timer: 3000
        });
      }
    });
  };

  render() {
    const { courseData, nextSession, details, previousSession } = this.state;
    console.log(details, "course data");
    return (
      <div className="DescCouses py-5">
        {details ? (
          <div className="container">
            <div className="row">
              <div className="kt-portlet p-4">
                <div className="col-md-12">
                  <h1 className="h3">
                    {courseData ? courseData.name : null}
                    <small
                      className={`float-right btn p-2 rounded mt-2 
                        ${details.status === "Completed" && "btn-success"}
                        ${details.status === "Active" && "btn-info"}
                        ${details.status === "Waiting" && "btn-warning"}
                        ${details.status === "Terminated" && "btn-danger"}
                        ${!["Completed", "Active", "Waiting", "Terminated"].includes(details.status) && "btn-primary"}
                        `}
                    >
                      {details ? details.status : null}
                    </small>
                  </h1>
                  <span>
                    <i className="fas fa-calendar-week"></i> Start Date{" "}
                    {details ? Moment(details.start_at).format("ll") : null} -{" "}
                  </span>
                  <span>Branch {courseData ? courseData.branch : null} </span>

                  {/*  */}
                  <div className="kt-widget5">
                    <div className="row mt-4">
                      <div className="col-md-5">
                        <img
                          alt=""
                          className="img-fluid w-100 course-image border"
                          src={
                            courseData.image
                              ? imgURL + courseData.image
                              : placeholder
                          }
                        />
                      </div>
                      <div className="col-md-7">
                        <div className="kt-widget5__section text-left">
                          <h5 className="kt-widget5__title">About Course </h5>
                          <ul className="list-unstyled">
                            <li>Level: {details.level}</li>

                            <li>
                              Number Of Hours:{" "}
                              {courseData
                                ? courseData.hours_per_level * courseData.levels
                                : null}{" "}
                            </li>
                            {/* courseData.hours_per_level * courseData.levels / courseGroup.hours  */}
                            {details.courseGroup && (
                              <li>
                                Number Of Seesions:{" "}
                                {(courseData.hours_per_level *
                                  courseData.levels) /
                                  details.courseGroup.hours}
                              </li>
                            )}
                          </ul>
                          {details.courseGroup && (
                            <>
                              <h5 className="kt-widget5__title">
                                About Group{" "}
                              </h5>
                              <ul className="list-unstyled">
                                <li>name: {details.courseGroup.name}</li>

                                <li>
                                  instructor:{" "}
                                  {details.courseGroup.instructor
                                    ? details.courseGroup.instructor.name
                                    : null}
                                </li>
                              </ul>
                            </>
                          )}
                          <div className="kt-widget5__info">
                            <p>{courseData ? courseData.description : null}</p>
                          </div>
                          <div className="row mt-4">
                            <div className="col-md-3">
                              <img
                                alt=""
                                className="img-fluid rounded-circal"
                                src={toAbsoluteUrl(
                                  "/media/products/product6.jpg"
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}

                  <CourseExams
                    studentCourseId={this.props.match.params.DescCouses_id}
                  />

                  <div className="row">
                    <div className="col-md-6">
                      <CoursePayments
                        id={this.props.match.params.DescCouses_id}
                      />
                    </div>
                    <div className="col-md-6 border-left">
                      <DownloadFiles
                        studentCourseId={this.props.match.params.DescCouses_id}
                      />
                    </div>
                    <div className="col-md-12">
                      <Attendance id={this.props.match.params.DescCouses_id} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 mb-4">
                <div className="row row-full-height">
                  <div className="col-sm-12">
                    <div className="kt-portlet--height-fluid-half px-3 pt-5 kt-portlet--border-bottom-brand kt-portlet text-center  h-100">
                      <p
                        className="mt-3 text-success"
                        style={{ fontSize: "1.75rem" }}
                      >
                        {this.state.remaining}
                      </p>
                      <h6 className="text-danger">Remaining</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="row">
                  <div className="col-6">
                    <div className="next-session kt-portlet p-4">
                      {previousSession ? (
                        <>
                          <h6> Previous Session </h6>
                          <p>
                            {" "}
                            <i className="fas fa-calendar-alt"></i>{" "}
                            {Moment(previousSession.day).format("ll")}
                          </p>
                          <div className="row">
                            <div className="col-6">
                              <i className="fas fa-clock"></i> From
                              {previousSession.from_time}
                            </div>
                            <div className="col-6">
                              <i className="fas fa-clock"></i> To{" "}
                              {previousSession.to_time}{" "}
                            </div>
                          </div>
                          <button
                            className="btn btn-block btn-info mt-3"
                            disabled
                          >
                            {previousSession.attendance}
                          </button>
                        </>
                      ) : (
                        <h5 className="text-info mx-auto">
                          No Previous Sessions
                        </h5>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="next-session kt-portlet p-4">
                      {nextSession ? (
                        <>
                          <h6> Next Session </h6>
                          <p>
                            {" "}
                            <i className="fas fa-calendar-alt"></i>{" "}
                            {Moment(nextSession.day).format("ll")}
                          </p>
                          <div className="row">
                            <div className="col-6">
                              <i className="fas fa-clock"></i> From
                              {nextSession.from_time}
                            </div>
                            <div className="col-6">
                              <i className="fas fa-clock"></i> To{" "}
                              {nextSession.to_time}{" "}
                            </div>
                          </div>
                          <ConfirmationModal
                            name="Apologize"
                            tital="Session Apologize"
                            text="are you sure you wonna apologize this session"
                            buttonClasses="btn btn-danger"
                            btn=" btn-block mt-3"
                            onSubmit={() => {
                              this._apologize(nextSession.id);
                            }}
                          />
                        </>
                      ) : (
                        <h5 className="text-info mx-auto">
                          No Scheduled Sessions
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="loader mx-auto "></div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(DescCouses);
