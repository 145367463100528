import React, { Fragment } from "react";

const PrevSessions_Summary = ({ data_prev }) => {
  return (
    <Fragment>
      <div className="next-session kt-portlet p-4">
        {Object.keys(data_prev).length ? (
          <>
            <h6> Previous Session </h6>
            <h5> {data_prev ? data_prev.course_name : null} </h5>
            <p>
              {" "}
              <i className="fas fa-calendar-alt"></i>{" "}
              {data_prev ? data_prev.day : null}{" "}
            </p>
            <div className="row">
              <div className="col-6">
                <i className="fas fa-clock"></i> From
                {data_prev ? data_prev.from_time : null}
              </div>
              <div className="col-6">
                <i className="fas fa-clock"></i> To{" "}
                {data_prev ? data_prev.to_time : null}{" "}
              </div>
            </div>
            <button disabled className="btn btn-info btn- mt-3 btn-block">
              {" "}
              {data_prev.status}{" "}
            </button>
          </>
        ) : (
          <h5 className="text-info mx-auto">No Previous Sessions</h5>
        )}
      </div>
    </Fragment>
  );
};

export default PrevSessions_Summary;
