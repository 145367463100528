import React, { Component } from "react";
import authentic from "../../../_metronic/_assets/media/img/authentic.png";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { CallApi } from "../../api";

class ResetPassword extends Component {
  state = {
    old_password: "",
    newPassword: "",
    rpeat_new_password: ""
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmite = e => {
    e.preventDefault();
    const { old_password, newPassword, rpeat_new_password } = this.state;
    console.log(
      old_password,
      newPassword,
      rpeat_new_password,
      "new_password from api"
    );

    if (newPassword != rpeat_new_password) {
      Swal.fire({  toast:true,
        position: "top-end",
        icon: "error",
        title: "the new password is not matching",
        showConfirmButton: false,
        timer: 3000
      });
    } else {
      let bodyFormData = new FormData();
      bodyFormData.append("old_password", old_password);
      bodyFormData.append("new_password", newPassword);
      CallApi("POST", "/student/reset-password", {}, bodyFormData).then(
        response => {
          if (response.status == 200) {
            Swal.fire({  toast:true,
              position: "top-end",
              icon: "success",
              title: "the new password is saved",
              showConfirmButton: false,
              timer: 3000
            });
          } else {
            Swal.fire({  toast:true,
              position: "top-end",
              icon: "error",
              title: "sorry the old password is not correct",
              showConfirmButton: false,
              timer: 3000
            });
          }
        }
      );
    }
  };

  render() {
    //   const {user} = this.props
    return (
      <div className="ResetPassword py-5">
        <div className="container">
          <div className="content kt-portlet">
            <div className="col-md-10 mx-auto p-4">
              <div className="col-md-4 mx-auto">
                <h1 className="text-center h3"> Change Password </h1>
                <img src={authentic} className="img-fluid mx-auto" alt="" />
              </div>
              <form className="" onSubmit={this.handleSubmite}>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="old_password">Old Password</label>
                      <input
                        onChange={this.handleChange}
                        type="password"
                        className="form-control"
                        id="old_password"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="newPassword">New Password</label>
                      <input
                        type="password"
                        className="form-control"
                        onChange={this.handleChange}
                        id="newPassword"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="rpeat_new_password">
                        Repeat New Password
                      </label>
                      <input
                        onChange={this.handleChange}
                        type="password"
                        className="form-control"
                        id="rpeat_new_password"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-3  ml-auto">
                    <button className="btn btn-info btn-block">
                      {" "}
                      save change{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(ResetPassword);
