import React, { Component } from "react";
import { connect } from "react-redux";
import "./exmas.scss";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { CallApi } from "../../api";
import { actions } from "../../store/ducks/exam.duck";
import Question from "./Question";
import Swal from "sweetalert2";
import CountdownExam from "./CountdownExam";

class ExamDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inProp: false,
      topic_type: "",
      topic_name: "",
      questions: [],
      answers: [],
      answer: 0,
      active: 1,
      examScoreData: null,
    };
  }
  componentDidMount() {
    this.props.exam.status === "In Progress" && this.checkExamTime();
    this.props.exam.status === "Completed" && this.getExamScore();
    this.getExamQuestions();
    this.getExam();
  }
  getExam() {
    CallApi(
      "GET",
      `/exam/show-student-course-exam/${this.props.exam.id}`,
      {},
      {}
    ).then((response) => {
      if (response.status == 200) {
        this.props.setExam(response.data.data);
      }
    });
  }
  submitExam = () => {
    if (this.props.exam.status === "In Progress") {
      const data = {
        examId: this.props.exam.id,
      };
      CallApi(
        "POST",
        "/exam/submit-student-course-question-answer",
        {},
        data
      ).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "you exam has been submitted",
            showConfirmButton: false,
            timer: 3000,
          });
          this.getExam();
        }
      });
    }
  };

  getExamScore = () => {
    CallApi(
      "GET",
      `/exam/get-student-course-exam-score/${this.props.exam.id}`,
      {},
      {}
    ).then((response) => {
      if (response.status == 200) {
        this.setState({
          examScoreData: response.data.data,
        });
      }
    });
  };
  checkExamTime = () => {
    CallApi(
      "GET",
      `/exam/check-student-course-exam/${this.props.exam.id}`,
      {},
      {}
    ).then((response) => {
      if (response.status === 200) {
      } else if (response.status === 202) {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "error",
          title: response.data.massage,
          showConfirmButton: false,
          timer: 3000,
        });
        this.getExam();
      }
    });
  };
  getExamQuestions = () => {
    if (this.props.exam.status !== "New") {
      const examId = this.props.match.params.id;
      CallApi(
        "GET",
        `/exam/get-student-course-exam-questions/${examId}`,
        {},
        {}
      ).then((response) => {
        if (response.status === 200) {
          this.setState({
            questions: response.data.data,
          });
        }
      });
    }
  };
  answerQuestion = (number) => {
    const { answers } = this.props;
    if (!answers.includes(number)) {
      this.props.addAnswer(number);
      this.setState({
        answer: 1 + this.state.answer,
      });
    }
  };
  changeActive = (active) => {
    this.setState({
      active,
    });
  };
  startExam = () => {
    if (this.props.exam.status == "New") {
      CallApi(
        "PUT",
        `/exam/start-student-course-exam/${this.props.exam.id}`,
        {},
        {}
      ).then((response) => {
        if (response.status == 200) {
          this.props.setExam(response.data.data);
          this.props.setTimer(true);
          this.getExam();
          this.getExamQuestions();
          this.setState({
            inProp: true,
          });
        } else if (response.status == 202) {
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: "error",
            title: response.data.massage,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      });
    }
  };

  handleTimeOnClick = (e) => {
    e.preventDefault();
    this.setState({
      active: this.state.active - 1,
    });
    const { exam } = this.props;
    let time =
      (exam.category.duration_in_minutes * 60 + exam.remainingSeconds) * 1000;
    console.log(time);
  };
  render() {
    const { exam, answers } = this.props;
    const { questions, answer, active, examScoreData } = this.state;

    return (
      <div className="ExamDetails py-5">
        {/* <CSSTransition in={inProp} timeout={200} classNames="timer"> */}
        {exam.status == "In Progress" && (
          <>
            <CountdownExam exam={exam} className="submit-exam" />
            <br />
            <div className="submit-btn-wrapper pr-5">
              <div className="submit-btn ">
                <button
                  className="btn btn-outline-danger rounded-pill btn-lg"
                  onClick={(e) => {
                    e.preventDefault();
                    this.checkSubmit();
                  }}
                >
                  {" "}
                  Finish Exam{" "}
                </button>
              </div>
            </div>
          </>
        )}
        {/* </CSSTransition> */}
        <div className="container">
          {/* degree */}
          {exam.status == "Completed" && examScoreData && (
            <div className="col-md-10 mx-auto">
              <div className="degree bg-white p-5">
                <h5 className="text-center text-nuted">
                  {exam.category.name}{" "}
                </h5>

                <div className="row mt-5 pt-3">
                  <div className="col-md-7  ">
                    <ul className="list-group mt-1">
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Number of questions:
                        <span className="badge badge-primary badge-pill">
                          {examScoreData.total}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Correct answers:
                        <span className="badge badge-success badge-pill">
                          {examScoreData.correct}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Wrong answers:
                        <span className="badge badge-danger badge-pill">
                          {examScoreData.wrong}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Not answered:
                        <span className="badge badge-warning badge-pill">
                          {examScoreData.not_answered}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Score
                        <span className="badge badge-primary badge-pill">
                          {exam.score}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-2  m-auto">
                    <CircularProgressbar
                      value={examScoreData.percentage}
                      text={`${examScoreData.percentage}%`}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {exam.status == "New" && (
            <div className="col-md-10 mx-auto bg-white p-5">
              <h5 className="text-center text-nuted">{exam.category.name} </h5>
              <h6 className="text-center text-nuted mt-3">
                {`${exam.category.duration_in_minutes} Minutes`}{" "}
              </h6>
              <div className="text-center justfiy-center mt-3">
                <button className="btn btn-info " onClick={this.startExam}>
                  Start Exam
                </button>
              </div>
            </div>
          )}
          <div className="row">
            {exam.status == "In Progress" && (
              <div className="col-md-10 mx-auto">
                <div className="progress mb-4" style={{ textAlign: "" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${(answer / questions.length) * 100}%` }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      paddingTop: "10px",
                    }}
                  >
                    {((answer / questions.length) * 100).toFixed(2)}%
                  </div>
                </div>
              </div>
            )}

            {exam.status != "New" && (
              <form className="col-md-10 mx-auto">
                {questions.map((question, i) => {
                  return (
                    <div key={i}>
                      <Question
                        status={exam.status}
                        count={i + 1}
                        active={active}
                        examId={this.props.match.params.id}
                        question={question}
                        onAnswer={this.answerQuestion}
                        timeOut={this.getExam}
                      />
                    </div>
                  );
                })}
                {questions.length > 0 && (
                  <div className="d-flex justify-content-between w-100">
                    <button
                      className="btn btn-outline-info rounded-pill btn-lg"
                      onClick={this.handleTimeOnClick}
                      disabled={active === 1}
                    >
                      {" "}
                      Previous{" "}
                    </button>
                    <button
                      className=" btn btn-outline-info rounded-pill btn-lg"
                      onClick={(e) => {
                        e.preventDefault();
                        this.changeActive(active + 1);
                      }}
                      disabled={active === questions.length}
                    >
                      {" "}
                      Next{" "}
                    </button>
                  </div>
                )}
                <br />
                {questions.length > 0 && (
                  <div
                    className="row"
                    style={{
                      height: "20vh",
                      overflow: "scroll",
                      maxWidth: "100%",
                      overflowX: "hidden",
                    }}
                  >
                    {questions.map((question, i) => {
                      return (
                        <div className="col-md-1 offset-md-0 offset-1 col-2">
                          <a
                            style={
                              i + 1 > Math.max(...answers)
                                ? {
                                    pointerEvents: "none",
                                    opacity: 0.4,
                                  }
                                : {}
                            }
                            className={`btn btn-icon btn-circle mr-2 mt-1
                          ${
                            active == i + 1
                              ? "btn-info"
                              : answers.includes(i + 1)
                              ? "btn-success"
                              : "btn-outline-info"
                          }
                        `}
                            onClick={(e) => {
                              e.preventDefault();
                              if (Math.max(...answers) >= i + 1) {
                                this.changeActive(i + 1);
                              }
                            }}
                          >
                            {i + 1}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                )}
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ exam }) => ({
  exam: exam.exam,
  timer: exam.timer,
  answers: exam.answers,
});
const mapActionsToProps = {
  setTimer: actions.setTimer,
  setExam: actions.setExam,
  addAnswer: actions.addAnswer,
};
export default connect(mapStateToProps, mapActionsToProps)(ExamDetails);
