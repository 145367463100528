/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../utils/utils";
import * as builder from "../../ducks/builder";
import facebook from "../../_assets/media/img/facebook.7ee30deb.svg";
import youtube from "../../_assets/media/img/youtube.1f7b3ab4.svg";
import instagram from "../../_assets/media/img/instagram.92711c24.svg";
class Footer extends React.Component {
  render() {
    const today = new Date().getFullYear();
    const {
      footerSelfLayoutIsExtended,
      footerClasses,
      footerContainerClasses
    } = this.props;
    return (
      <div
        className={`kt-footer ${footerClasses} kt-grid__item`}
        id="kt_footer"
      >
        {footerSelfLayoutIsExtended && (
          <div className="kt-footer__top">
            <div className={`kt-container ${footerContainerClasses}`}>
              <div className="row">
                <div className="col-lg-4">
                  <div className="kt-footer__section">
                    <h3 className="kt-footer__title">About</h3>
                    <div className="kt-footer__content ml-4">
                      We Seek To Achieve Leadership In The Field Of Teaching And
                      Practicing Languages ​​In Egypt According To The Latest
                      Teaching Methods And Approved Educational Curricula.
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="kt-footer__section">
                    <h3 className="kt-footer__title">Quick Links</h3>
                    <div className="kt-footer__content">
                      <div className="kt-footer__nav  ml-4">
                        <div className="kt-footer__nav-section">
                          <Link to="/dashboard" >Dashboard</Link>
                          <Link to="/Courses-Page" >Courses</Link>
                          <Link to="/Exams" >Exams</Link>
                        </div>
                        <div className="kt-footer__nav-section">
                          <Link to="/Ticket" >Tickets</Link>
                          <Link to="/My-Profile" >Profile</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="kt-footer__section">
                    <h3 className="kt-footer__title">Get In Touch</h3>
                    <div className="kt-footer__content">
                      <ul className="list-inline mt-4 ml-4 pr-0">
                        <li className="list-inline-item">
                          <a
                            href="https://www.instagram.com/real_soft_house/"
                            target="_blank"
                          >
                            <img src={instagram} className="img-fluid" alt="img" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="https://www.facebook.com/REALSOFTHOUSE/"
                            target="_blank"
                          >
                            <img src={facebook} className="img-fluid" alt="img" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="https://www.youtube.com/channel/UCKo2iiacnNxiD98yY0wJ4AQ"
                            target="_blank"
                          >
                            <img src={youtube} className="img-fluid" alt="img" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = store => ({
  footerSelfLayoutIsExtended:
    objectPath.get(store.builder.layoutConfig, "footer.self.layout") ===
    "extended",
  footerClasses: builder.selectors.getClasses(store, {
    path: "footer",
    toString: true
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: "footer_container",
    toString: true
  })
});

export default connect(mapStateToProps)(Footer);
