import React, { Suspense } from "react";
import { connect } from "react-redux";
import PrevSessions_Summary from "./PrevSessions_Summary";
import NextSession_Summary from "./NextSessions_Summary";
import user_img from "../../../_metronic/_assets/media/img/placeholder.png";
import Swal from "sweetalert2";

import { LayoutSplashScreen } from "../../../_metronic";
import { Component } from "react";
import { CallApi } from "../../api";
const imgURL = process.env.REACT_APP_BASE_IMGURL;

class Dashboard extends Component {
  state = {
    next_session: [],
    prev_session: [],
    remaining: 0
  };

  componentDidMount() {
    // next sessions
    CallApi("GET", "/student/next-session", {}, {}).then(response => {
      if (response) {
        if (response.status == 200) {
          this.setState({ next_session: response.data });
        }
      }
    });
    // prev sessions
    CallApi("GET", "/student/prev-session", {}, {}).then(response => {
      if (response) {
        if (response.status == 200) {
          this.setState({ prev_session: response.data });
        }
      }
    });

    CallApi("GET", "/student/remaining", {}, {}).then(response => {
      if (response) {
        if (response.status == 200) {
          this.setState({ remaining: response.data.data });
        }
      }
    });
  }

  apologizeAPi = course_id => {
    CallApi("PUT", `/student/apologize/${course_id}`, {}, {}).then(response => {
      if (response.status == 200) {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Apologize The Next Session",
          showConfirmButton: false,
          timer: 3000
        });
      }
    });
  };

  render() {
    const { user } = this.props;
    const { next_session, prev_session, remaining } = this.state;

    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <div className="row pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="profile-info kt-portlet p-5 mt-4 text-center">
                  <div className="co-md-6 mx-auto">
                    <img
                      src={
                        user
                          ? user.profile_image
                            ? imgURL + user.profile_image
                            : user_img
                          : user_img
                      }
                      className="img-fluid rounded-circal border"
                      style={{
                        width: "140px",
                        height: "140px",
                        borderRadius: "50%"
                      }}
                      alt=""
                    />
                    <h1 className="mt-4"> {user ? user.name : null} </h1>
                    <h6 className="mt-0 mt-0">
                      {" "}
                      {user ? user.address : null}{" "}
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 mb-4">
                <div className="row row-full-height">
                  <div className="col-sm-12">
                    <div className="kt-portlet--height-fluid-half px-3 pt-5 kt-portlet--border-bottom-brand kt-portlet text-center  h-100">
                      <p
                          className="mt-3 text-success"
                          style={{ fontSize: "1.75rem" }}
                      >
                        {remaining} LE
                      </p>
                      <h6 className="text-danger">Remaining</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                {/* <div className="kt-portlet p-4 px-5">
                  <div className="row">
                    <div className="col-md-3">
                      <i className="flaticon-safe-shield-protection kt-font-success fa-5x"></i>
                    </div>
                    <div className="col-md-5">
                      <h6 className="mt-3"> Lorem ipsum dolor. </h6>
                      <small>
                        {" "}
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Ratione.{" "}
                      </small>
                    </div>
                    <div className="col-md-4">
                      <h3 className="text-success text-right mt-4"> 456+ </h3>
                    </div>
                  </div>
                </div> */}
                <div className="row row-full-height">
                  <div className="col-md-6">
                    <PrevSessions_Summary data_prev={prev_session} />
                  </div>
                  <div className="col-md-6">
                    {next_session && (
                      <NextSession_Summary
                        apologize={this.apologizeAPi}
                        data_next={next_session}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user: user
});

export default connect(mapStateToProps)(Dashboard);
