/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { CallApi } from "../api";
import { toAbsoluteUrl } from "../../_metronic/utils/utils";
import { Table } from "react-bootstrap";
import File from "./file";
import "./table.scss";
const imgURL = process.env.REACT_APP_BASE_IMGURL;

export default function DownloadFiles({ studentCourseId }) {
  const [files, setFiles] = useState({});
  useEffect(() => {
    CallApi(
      "GET",
      `/student/get-student-course-files/${studentCourseId}`,
      {},
      {}
    ).then(response => {
      if (response.status == 200) {
        setFiles(response.data.data);
      }
    });
  }, []);
  return (
    <>
      <div className="kt-portlet__head bg-light">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Download Files</h3>
        </div>
      </div>
      <div className="kt-portlet__body">
        <div className="kt-widget4">
          <Tabs
            defaultActiveKey="courseFiles"
            transition={false}
            id="noanim-tab-example"
          >
            <Tab eventKey="courseFiles" title="Course Files">
              {files.courseFiles && (
                <Table
                  striped
                  hover
                  className="table-view table-vertical-center"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Level</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.courseFiles.map((file, i) => {
                      return (
                        <tr key={i}>
                          <td>{file.name}</td>
                          <td>{file.level}</td>
                          <td>
                            <File url={imgURL+file.url} name={file.name} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Tab>
            <Tab eventKey="groupFiles" title="Group Files">
              {files.groupFiles && (
                <Table
                  striped
                  hover
                  className="table-view table-vertical-center"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Level</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.groupFiles.map((file, i) => {
                      return (
                        <tr key={i}>
                          <td>{file.name}</td>
                          <td>{file.level}</td>
                          <td>
                            <File url={imgURL+file.url} name={file.name} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}
