import React, { useState } from "react";
import { Modal } from "react-bootstrap";

export function ConfirmationModal({name, tital, text, buttonClasses, onSubmit, btn}) {
  const [toggle, setToggle] = useState(false);
  const _toggle = () => {
    setToggle(!toggle);
  };
  const _submit = () => {
    onSubmit()
    _toggle()
  };
  return (
    <>
      <button className={buttonClasses+btn} onClick={_toggle}>
        {name}
      </button>
      <Modal show={toggle} onHide={_toggle}>
        <Modal.Header >
          <Modal.Title>{tital}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <spam>{text}</spam>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={_toggle}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={_submit}
            className={buttonClasses}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
