import React, { Component } from "react";
import user_img from "../../../_metronic/_assets/media/img/user.jpg";
import "./profile_style.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { CallApi } from "../../api";
import { actions } from "../../store/ducks/auth.duck";
const imgURL = process.env.REACT_APP_BASE_IMGURL;

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      imagePreviewUrl: "",
      name: this.props.user.name,
      email: this.props.user.email,
      address: this.props.user.address,
      mobile: this.props.user.mobile,
      university: this.props.user.university,
      faculty: this.props.user.faculty,
      profile_pic: "",
    };
  }

  _handleSubmit(e) {
    e.preventDefault();
    // TODO: do something with -> this.state.file
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      email,
      address,
      mobile,
      university,
      faculty,
      file,
    } = this.state;

    if ((!name, !email, !address, !university)) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Please enter your full information",
        showConfirmButton: false,
        timer: 3000,
        toast: true,
      });
    } else {
      let bodyFormData = new FormData();
      bodyFormData.append("name", name);
      bodyFormData.append("email", email);
      bodyFormData.append("address", address);
      bodyFormData.append("university", university);
      bodyFormData.append("faculty", faculty);
      bodyFormData.append("profile_pic", file);

      CallApi("POST", "/student/student", {}, bodyFormData).then((response) => {
        if (response.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your information updated successfully",
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
          this.props.fulfillUser(response.data);
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Failed to update your information",
            showConfirmButton: false,
            timer: 3000,
            toast: true,
          });
        }
      });
    }
  };

  render() {
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (
        <img
          src={imagePreviewUrl}
          className="img-fluid rounded-circal d-block mx-auto "
        />
      );
    } else {
      $imagePreview = (
        <img
          src={imgURL + this.props.user.profile_image}
          className="img-fluid rounded-circal d-block mx-auto "
        />
      );
    }
    const { user } = this.props;

    return (
      <div className="My-Profile py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="profile-info kt-portlet py-5 mt-4 ">
                <div className="co-md-6 mx-auto text-center">
                  <div className="imgPreview text-center mx-auto">
                    <div className="upload-btn-wrapper upload-icon">
                      <button className="btn">
                        {" "}
                        <i className="fas fa-cloud-upload-alt"></i>{" "}
                      </button>
                      <input
                        type="file"
                        name="myfile"
                        onChange={(e) => this._handleImageChange(e)}
                      />
                    </div>
                    {/* <div className="upload-icon rounded-circal bg-white">
                           
                        </div> */}
                    {$imagePreview}
                  </div>
                </div>
                <div className="col-md-10 mx-auto mt-5">
                  <div className="previewComponent">
                    <form onSubmit={(e) => this._handleSubmit(e)}>
                      {/* <button className="submitButton" 
                            type="submit" 
                            onClick={(e)=>this._handleSubmit(e)}>Upload Image</button> */}
                    </form>
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="Name">Name</label>
                          <input
                            type="text"
                            onChange={this.handleChange}
                            value={this.state.name}
                            className="form-control"
                            id="name"
                            minLength="3"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="mobile">Mobile</label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.mobile}
                            id="mobile"
                            disabled={true}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="address">Address</label>
                          <input
                            onChange={this.handleChange}
                            value={this.state.address}
                            type="text"
                            className="form-control"
                            minLength="10"
                            id="address"
                            required
                          />
                        </div>
                        {/*<Link*/}
                        {/*  to="/ResetPassword"*/}
                        {/*  className="bg-success text-white px-2 py-1"*/}
                        {/*>*/}
                        {/*  Change Password*/}
                        {/*</Link>*/}
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.email}
                            id="email"
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="faculty">College</label>
                          <input
                            onChange={this.handleChange}
                            value={this.state.faculty}
                            type="text"
                            className="form-control"
                            id="faculty"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="university">University</label>
                          <input
                            onChange={this.handleChange}
                            value={this.state.university}
                            type="text"
                            className="form-control"
                            id="university"
                            required
                            minLength="2"
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary btn-block mt-5 pt-2"
                        >
                          {" "}
                          Submit{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});
const mapActionToProps = {
  fulfillUser: actions.fulfillUser,
};
export default connect(mapStateToProps, mapActionToProps)(MyProfile);
