import React from "react";
import logo from "../../../_metronic/_assets/media/img/logo-2.png";
import "./Ticket.scss";
const imgURL = process.env.REACT_APP_BASE_IMGURL;

const Reply = ({ userData, data }) => {
  return (
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-body card-container">
          <div className="row col-xl-7">
            <div className="border-right ">
              <img
                src={
                  userData && data.model_type.includes("User")
                    ? logo
                    : imgURL + userData.profile_image
                }
                className="rounded-circal user-logo mr-3 ml-2"
                alt="user-img"
              />
            </div>
            <div className="ml-2 col-xs-6">
              <h5 className="card-title">
                {data && data.model_type.includes("User")
                  ? "Real Soft House"
                  : userData.name}{" "}
                <small className="text-muted user-date ml-3 pt-1">
                  {" "}
                  {data ? data.updated_at : null}{" "}
                </small>{" "}
              </h5>
              <p className="card-text">{data ? data.message : null}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reply;
