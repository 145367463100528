/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CallApi } from "../api";
import Moment from "moment";
import { useDispatch } from "react-redux";
import { actions } from "../store/ducks/exam.duck";

export default function CourseExams({ studentCourseId }) {
  const [exams, setExams] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    CallApi(
      "GET",
      `/exam/get-student-course-exams/${studentCourseId}`,
      {},
      {}
    ).then(response => {
      if (response.status == 200) {
        setExams(response.data.data);
        // this.setState({Course_data : response.data })
      }
    });
  }, []);
  return (
    <>
      <div className="kt-portlet__head bg-light mt-5 ">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Exam</h3>
        </div>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Score</th>
            <th>Level</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {exams.map((exam, i) => {
            return (
              <tr key={i}>
                <td>{exam.category.name}</td>
                <td>{Moment(exam.date).format("ll")}</td>
                <td>{exam.score}</td>
                <td>{exam.level}</td>
                <td>{exam.status}</td>
                <td>
                  <Link
                    to={`/Exams/${exam.id}`}
                    className="btn btn-outline-brand btn-elevate btn-circle btn-icon"
                    onClick={e => {
                      dispatch(actions.setExam(exam));
                    }}
                  >
                    <i className="flaticon-eye"></i>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
