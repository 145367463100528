import React from "react";
import { Link } from "react-router-dom";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import placeholder from "../../../_metronic/_assets/media/img/placeholder.png";
import "./courses.scss";
const imgURL = process.env.REACT_APP_BASE_IMGURL;

const CourseSummary = ({ data }) => {
  return (
    <div className="col-md-4 mt-3">
      <Link to={"/Courses-Page/"+ data.student_course_id }>
        <Card>
          <Card.Img
            variant="top"
            src={data.picture ? imgURL+data.picture : placeholder}
          />
          <Card.Body>
            <Card.Title className="text-dark pb-3">
              {data ? data.name : null}
              <small className={"float-right course-status btn p-2 rounded mt-n5 " + 
              (data.student_course_status === "Completed" ? "btn-success" : data.student_course_status === "Active" ? "btn-info" : data.student_course_status === "Waiting" ? "btn-warning" : data.student_course_status === "Terminated" ? "btn-danger" : "btn-primary")
              }>
                {data ? data.student_course_status : null}
              </small>
            </Card.Title>
            <Card.Text>{data ? data.description : null}</Card.Text>
            <div className="row">
              <div className="col-6">
                <span className="list-inline-item text-info">
                  {" "}
                  <i className="fas fa-calendar-week"></i>
                  {" "}
                  { data ? data.start_at : null}
                </span>
              </div>
              <div className="col-6">
                <span className="list-inline-item text-info">
                  {" "}
                  <i className="fas fa-calendar-week"></i>
                  {" "}
                  { data ? data.end_at : null}
                </span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Link>
    </div>
  );
};

export default CourseSummary;
